import axios from "axios";
import {uniqueArray} from "@avvoka/shared";

export async function download_report(id: number, commit){
  if(commit) commit('SET_DOWNLOADING', true)

  await window.avv_download(`/custom_reports/${id}/download`, {}, 'get')

  if(commit) commit('SET_DOWNLOADING', false)
}

export const handleSaveResponse = (data: Record<string, any>, isNew = false): boolean => {
  const hasErrors = 'errors' in data
  if(hasErrors) {
    const errorMessages = uniqueArray(Object.values(data.errors))
    errorMessages.forEach((msg: any) => {
      avv_dialog({snackStyle: 'error', snackMessage: msg})
    })
  }
  else avv_dialog({snackStyle: 'notice', snackMessage: localizeText('custom_reports.notices.saved_successfully')})
  return !hasErrors
}

export const saveAndRun = async (store) => {
  store.commit('SET_LOADING', true)
  store.commit('SET_RESULT', true)
  const success = await store.dispatch('save_report')
  if(!success) {
    store.commit('SET_LOADING', false)
    store.commit('SET_RESULT', false)
    return
  }

  const data_url = `/custom_reports/${store.state.report!.id}/report_data.json`
  const response = await axios.get(data_url)
  store.commit('SET_RESULT_HTML', response.data.html)
  store.commit('SET_LOADING', false)
}
