import {computed, reactive, type Ref, ref, watch, unref} from "vue";
import {
  type AstFunctionId,
  type AstStoreType,
  type AstType,
  type SuperAstType
} from "../ast";
import {type Store} from "vuex";

export const useRelationSelect = (ast: ReturnType<typeof useAstBuilder>, initialValue: any, valuesResolver: (valueSearch: Ref<string>) => Ref, addValue?: (valueSearch: Ref<string>) => void) => {
  const value = ref(initialValue)
  const valueSearch = ref("")
  const values = valuesResolver(valueSearch);
  watch(value, (newValue) => ast.relationValue.value = newValue)
  const onSubmitSelect = (e: KeyboardEvent | MouseEvent) => {
    if("code" in e && e.code.indexOf("Enter") <= -1) return;
    if("code" in e) {e.preventDefault();}

    if(values.value.some(attr => attr.toLowerCase().trim()) === valueSearch.value.toLowerCase().trim()) return;
    value.value = valueSearch.value;
    valueSearch.value = "";
    if(addValue) addValue(value.value)
  }
  return {
    value, valueSearch, onSubmitSelect, values
  }
}

export const useDatasheetSelect = (ast: ReturnType<typeof useAstBuilder>, store: Store<AstStoreType>) => {
  return useRelationSelect(
    ast,
    ast.relation.value && ast.relationName.value.indexOf("String") > -1 ? ast.relationValue.value : "",
    (valueSearch) => computed(() => AvvStore.getters.datasheetNames.filter(att => att.toLowerCase().indexOf(valueSearch.value.toLowerCase()) > -1 && att.length > 0)))
}

export const useDependentListSelect = (ast: ReturnType<typeof useAstBuilder>, store: Store<AstStoreType>) => {
  return useRelationSelect(
    ast,
    ast.relation.value && ast.relationName.value.indexOf("String") > -1 ? ast.relationValue.value : "",
    (valueSearch) => computed(() => AvvStore.getters.listOfDependentNames.filter(att => att.toLowerCase().indexOf(valueSearch.value.toLowerCase()) > -1 && att.length > 0)))
}

export const useAttributeSelect = (ast: ReturnType<typeof useAstBuilder>, store: Store<AstStoreType>) => {
  return useRelationSelect(
    ast,
    ast.relation.value && ast.relationName.value.indexOf("Att") > -1 ? ast.relationValue.value : "",
    (valueSearch) => computed(() => (store.state.attributes ?? store.getters.date_attributes).filter(att => att.toLowerCase().indexOf(valueSearch.value.toLowerCase()) > -1 && att.length > 0)),
    (value) => store.commit('ADD_VALUE', {value, type: 'att'})
  )
}

export const useFixedValueSelect = (ast: ReturnType<typeof useAstBuilder>, store: Store<AstStoreType>) => {
  return useRelationSelect(
    ast,
    ast.relation.value && (ast.relationName.value === "String" || ast.relationName.value === "Number" || ast.relationName.value === "Iterator") ? String(ast.relationValue.value) : "",
    (valueSearch) => computed(() => store.state.values.filter(att => String(att).toLowerCase().indexOf(valueSearch.value.toLowerCase()) > -1 && att.length > 0)),
    (value) => store.commit('ADD_VALUE', {value, type: 'val'})
  )
}

export const useAstBuilder = (store: Store<AstStoreType>, uuid: Ref, parentUUID: Ref, makeReactive = false): ReturnType<typeof useAstBuilder> | Ref<ReturnType<typeof useAstBuilder>> => {
  const relation = computed(() => {
    const _uuid = unref(uuid);
    if (!_uuid) return null
    return store.state.byUUID[_uuid]
  });
  const relationName = computed({
    get: () => unref(relation) ? Ast.getNodeId(unref(relation), false) : null,
    set: (value) => store.commit("REPLACE_RELATION_ID", {uuid: unref(uuid), oldId: unref(relationName), newId: value})
  })
  const relationValue = computed({
    get: () => unref(relation) ? unref(relation)[unref(relationName)] : null,
    set: (value) => store.commit("REPLACE_RELATION_VALUE", {uuid: unref(uuid), id: unref(relationName), value: value})
  })

  /** Overrides existing relation with `value` */
  const setRelation = (value: SuperAstType) => {
    const rel = unref(relation)
    if (rel != null) {
      store.commit("REMOVE_RELATION", {uuid: unref(uuid), parent: unref(parentUUID)})
    }

    value.uuid = unref(uuid);
    store.commit("SET_RELATION", {value});
  }

  const appendRelation = (value: AstType | AstFunctionId) => {
    let result: any = value
    if (typeof value === "string") {
      if (["String", "Att", "Iterator", "AttArray", "DefaultAtt", "Number", "Sysdate", "Boolean"].includes(value)) {
        result = {[value]: ""}
      } else {
        result = {[value as any]: []}
      }
    }

    if(store.state.main == null || uuid.value == null) {
      setRelation(result);
    } else {
      store.commit("APPEND_RELATION", {uuid: unref(uuid), value: result})
    }
  }

  const wrapRelation = (type: AstFunctionId, insert: {append?: Array<AstType>, prepend?: Array<AstType>} = {}) => {
    store.commit("WRAP_RELATION", {uuid: unref(uuid), parent: unref(parentUUID), type, ...insert})
  }

  const unwrapRelation = () => {
    store.commit("UNWRAP_RELATION", {uuid: unref(uuid), parent: unref(parentUUID)})
  }

  const removeRelation = () => {
    store.commit("REMOVE_RELATION", {uuid: unref(uuid), parent: unref(parentUUID)})
  }

  const bIsEmpty = computed(() => {
    const value = unref(relationValue)
    if(value == null) return true;
    if(Array.isArray(value) && value.length === 0) return true;
    if(typeof value === "string" && value.length === 0) return true;
    return false
  })

  const bIsIterable = computed(() => {
    return Array.isArray(unref(relationValue))
  })

  const bIsGroup = computed(() => {
    return ["And", "Or"].includes(unref(relationName))
  })

  const bIsEndValue = computed(() => ["String", "Att", "AttArray", "DefaultAtt", "Number", "Iterator", "Sysdate", "Boolean"].includes(unref(relationName)))
  const bIsParentSame = computed(() => parentUUID == null || unref(uuid) === unref(parentUUID))

  const result = {
    uuid,
    relation,
    relationName,
    relationValue,
    bIsEmpty,
    bIsIterable,
    bIsGroup,
    bIsParentSame,
    bIsEndValue,

    setRelation,
    appendRelation,
    wrapRelation,
    unwrapRelation,
    removeRelation,
  }

  type NodeType = typeof result
  if(parentUUID != null) {
    (result as any).parentNode = computed(() => unref(bIsParentSame) ? null : useAstBuilder(store, unref(parentUUID), undefined, makeReactive))
  }
  const returnType = result as NodeType & {parentNode?: Ref<NodeType> | undefined};
  return makeReactive ? reactive(returnType) : returnType;
}
