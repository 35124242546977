<template>
  <div class="partial-widget">
    <div class="field text_field">
      <label>Name of Dependent list</label>
      <AvvSelect placeholder="Select a dependent list" :readonly="readonly" :value="dependentList.value" :search="true" @select="(option) => dependentList.value = option.getAttribute('value')">
        <div class="menu">
          <div class="custom-input z-10">
            <input v-model="dependentList.valueSearch" type="search" class="avv-input" placeholder="Search for a dependent list">
          </div>
          <avv-option v-for="name in dependentList.values" :key="name" :value="name" v-text="name"/>
        </div>
      </AvvSelect>
    </div>
    <div class="field text_field">
      <label>Lookup value</label>
      <AvvSelect placeholder="Select a lookup value" :readonly="readonly" :value="dependentListKey.value" :search="true" @select="(option) => dependentListKey.value = option.getAttribute('value')">
        <div class="menu">
          <div class="custom-input z-10">
            <input v-model="dependentListKey.valueSearch" type="search" class="avv-input" placeholder="Create or search for a lookup value" @keydown="dependentListKey.onSubmitSelect">
            <div class="avv-button" @click="dependentListKey.onSubmitSelect"><i class="material-icons" aria-hidden="true">add</i></div>
          </div>
          <avv-option v-for="name in dependentListKey.values" :key="name" :value="name" v-text="name"/>
        </div>
      </AvvSelect>
      <div class="help-text">This attribute is being used to resolve value of dependent list by comparing it with all keys</div>
    </div>
    <div class="field text_field">
      <label>Joining value</label>
      <input v-model="joiningValue" type="text">
      <div class="help-text">The joining value is useful in a case when you have multiple values with same keys.</div>
    </div>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, inject, reactive, ref, toRefs, watch} from "vue"
import {AstStore} from "../../ast";
import {useAstBuilder, useAttributeSelect, useDependentListSelect} from "../Reusable";
import AvvSelect from "../../../reusable/components/AvvSelect.vue"

export default defineComponent({
  name: "DependentList",
  components: {AvvSelect},
  props: ["uuid", "parent", "readonly"],
  setup(props) {
    const {uuid, parent, readonly} = toRefs(props);
    const store = inject(AstStore);
    const ast = useAstBuilder(store, uuid, parent)

    const joinAst = computed(() => ast.relationName.value === "Join" ? ast : null)
    const dependentListAst = computed(() => joinAst.value ? useAstBuilder(store, computed(() => joinAst.value.relationValue.value[0]), ast.uuid) : ast)
    const dependentListName = computed(() => useAstBuilder(store, computed(() => dependentListAst.value.relationValue.value[0]), dependentListAst.value.uuid))
    const dependentListKey = computed(() => useAstBuilder(store, computed(() => dependentListAst.value.relationValue.value[1]), dependentListAst.value.uuid))
    const joiningAst = computed(() => joinAst.value ? useAstBuilder(store, computed(() => joinAst.value.relationValue.value[1]), uuid) : null)
    const joiningValue = ref("")
    watch(uuid, () => joiningValue.value = joiningAst.value ? joiningAst.value.relationValue.value : "", {immediate: true});

    watch(joiningValue, (newValue) => {
      if(!newValue) {
        if(joiningAst.value != null) {
          joiningAst.value.setRelation(Ast.fromState({main: dependentListAst.value.uuid.value, byUUID: store.state.byUUID, ast_menu: null}));
        }
      } else {
        if(joinAst.value == null) {
          const stringUUID = ast
          store.commit("ADD_RELATION", {})
          ast.wrapRelation("Join", {append: [{String: newValue}]});
        } else {
          joiningAst.value.relationValue.value = newValue;
        }
      }
    })

    return {
      dependentList: reactive(useDependentListSelect(dependentListName.value, store)),
      dependentListKey: reactive(useAttributeSelect(dependentListKey.value, store)),
      readonly,
      joiningValue,
    }
  }
})
</script>
