export default class NumberUtils {
  public static extractOnlyValidNumberChars = (val: string, keepDots: boolean, keepCommas: boolean) => {
    const getRegExp = (keepDots: boolean, keepCommas: boolean) => {
      if(keepCommas && !keepDots) return /[^\d,]/g
      if(!keepCommas && keepDots) return /[^\d.]/g
      if(keepCommas && keepDots) return /[^\d.,]/g
      return /\D/g
    }

    return val.replace(getRegExp(keepDots, keepCommas),'');
  }

  public static isValidIndex = (index: unknown) => {
    return typeof index === 'number' && index >= 0;
  }
}
