import {type AstType} from "./ast";

export const DateWithinHuman = (ast: AstType) => {
  const dateCompareA = ast['And']![0]['DateCompare']!
  const dateCompareB = ast['And']![1]['DateCompare']!
  const dateOffset = dateCompareA[0]['DateOffset']! as []
  const [compareAttB, operator, offset, unit] = dateOffset.map(obj => Object.values(obj)[0])
  const compareAtt = dateCompareB[0]['Att']!
  const result = `DateWithin(${compareAtt}, ${compareAttB}, ${operator}, ${offset}, ${unit})`
  return replaceSystemAttributes(result)
}

export const replaceSystemAttributes = (humanReadableAst: string) => {
  const systemAttributes = [['_avvReportGenerationDate_', 'Report Generation Date']]
  return systemAttributes.reduce((acc, systemAttribute) => {
    return acc.replaceAll(systemAttribute[0], systemAttribute[1])
  }, humanReadableAst)
}
