<template>
  <div class="partial-widget">
    <div class="multiple-fields">
      <div class="field text_field">
        <label>Attribute</label>
        <AvvSelect placeholder="Select an attribute" :value="attribute.value" :readonly="readonly" :search="true" @select="(option) => attribute.value = option.getAttribute('value')">
          <div class="menu">
            <div class="custom-input z-10">
              <input v-model="attribute.valueSearch" type="search" class="avv-input" placeholder="Create or search an attribute" @keydown="attribute.onSubmitSelect">
              <div class="avv-button" @click="attribute.onSubmitSelect"><i class="material-icons" aria-hidden="true">add</i></div>
            </div>
            <avv-option v-for="name in attribute.values" :key="name" :value="name" v-text="name"/>
          </div>
        </AvvSelect>
      </div>
      <div class="field text_field">
        <label>Comparison</label>
        <AvvSelect placeholder="Select name of dependent list" :readonly="readonly" :value="equality" :search="true" @select="(option) => equality = option.getAttribute('value')">
          <div class="menu">
            <avv-option v-for="(item, name) in comparisonList" :key="name" :value="name" v-text="item.name"/>
          </div>
        </AvvSelect>
      </div>
      <div class="field text_field">
        <label>Value</label>
        <AvvSelect placeholder="Select a value" :value="fixedValue.value" :readonly="readonly" :search="true" @select="(option) => fixedValue.value = option.getAttribute('value')">
          <div class="menu">
            <div class="custom-input z-10">
              <input v-model="fixedValue.valueSearch" type="search" class="avv-input" placeholder="Create or search a value" @keydown="fixedValue.onSubmitSelect">
              <div class="avv-button" @click="fixedValue.onSubmitSelect"><i class="material-icons" aria-hidden="true">add</i></div>
            </div>
            <avv-option v-for="name in fixedValue.values" :key="name" :value="name" v-text="name"/>
          </div>
        </AvvSelect>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, inject, reactive, ref, toRefs, watch} from "vue"
import {AST_FUNCTIONS, AstGroupComparison, AstStore} from "../../ast";
import {useAstBuilder, useAttributeSelect, useFixedValueSelect} from "../Reusable";

export default defineComponent({
  name: "Equals",
  components: {AvvSelect: window.AvvSelect},
  props: ["uuid", "parent", "readonly"],
  setup(props) {
    const {uuid, parent, readonly} = toRefs(props);
    const store = inject(AstStore);
    const ast = useAstBuilder(store, uuid, parent)
    const values = computed(() => ast.relationValue.value.map(innerUUID => useAstBuilder(store, ref(innerUUID), uuid)) as Array<ReturnType<typeof useAstBuilder>>)

    const att = computed(() => values.value.find(val => val.relationName.value === "Att"))
    const val = computed(() => values.value.find(val => val.relationName.value !== "Att"))

    const equality = ref("Equals");
    const comparisonList = AstGroupComparison
    watch(equality, (newValue) => ast.relationName.value = newValue)

    return {
      attribute: computed(() => reactive(useAttributeSelect(att.value, store))),
      fixedValue: computed(() => reactive(useFixedValueSelect(val.value, store))),

      readonly,
      equality,
      comparisonList,
      AST_FUNCTIONS,
    }
  }
})
</script>