import {type ISchedule} from "./use-scheduler";
import {ordinalSuffixOf, humanTime, toDate} from "../../time_helpers/time_helpers";

export enum Intervals {
  day,
  week,
  month,
  year,
  once,
}

export enum WeekDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export enum Months {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}

export const isFromInterval = (intervals: Intervals[], interval: Intervals) => {
  return intervals.includes(interval)
}

const getScheduleDayTime = (schedule: ISchedule): string => {
  const { options } = schedule
  return humanTime(options.day_time.hour, options.day_time.minute)
}

export const getScheduleIntervalText = (schedule: ISchedule): string => {
  const { interval, options } = schedule
  switch (interval) {
    case Intervals.day:
      return `at ${getScheduleDayTime(schedule)}`
    case Intervals.week:
      return `${getScheduleWeekDays(schedule)} at ${getScheduleDayTime(schedule)}`
    case Intervals.month:
      return `on the ${ordinalSuffixOf(+schedule.start_date.split('-')[2])} at ${getScheduleDayTime(schedule)}`
    case Intervals.year:
      return `${getScheduleMonths(schedule)} at ${getScheduleDayTime(schedule)}`
    case Intervals.once:
      return `at ${getScheduleDayTime(schedule)}`
    default:
      return ''
  }
}

const getScheduleWeekDays = (schedule: ISchedule): string => {
  const { options } = schedule
  if(!options.week_days.length) return ''
  return 'on ' + options.week_days.map(day => {
    switch (day) {
      case WeekDays.Monday:
        return 'Monday'
      case WeekDays.Tuesday:
        return 'Tuesday'
      case WeekDays.Wednesday:
        return 'Wednesday'
      case WeekDays.Thursday:
        return 'Thursday'
      case WeekDays.Friday:
        return 'Friday'
      case WeekDays.Saturday:
        return 'Saturday'
      case WeekDays.Sunday:
        return 'Sunday'
      default:
        return ''
    }
  }).join(', ')
}

const getScheduleMonths = (schedule: ISchedule): string => {
  const { options } = schedule
  if(!options.months.length) return ''
  return 'in ' + options.months.map(month => {
    switch (month) {
      case Months.January:
        return 'January'
      case Months.February:
        return 'February'
      case Months.March:
        return 'March'
      case Months.April:
        return 'April'
      case Months.May:
        return 'May'
      case Months.June:
        return 'June'
      case Months.July:
        return 'July'
      case Months.August:
        return 'August'
      case Months.September:
        return 'September'
      case Months.October:
        return 'October'
      case Months.November:
        return 'November'
      case Months.December:
        return 'December'
    }
  })
}

export const getIntervalText = (interval: Intervals): string => {
  switch (interval) {
    case Intervals.day:
      return 'day'
    case Intervals.week:
      return 'week'
    case Intervals.month:
      return 'month'
    case Intervals.year:
      return 'year'
    default:
      return ''
  }
}

export const getEnumKeys = (enumType: any) => {
  return Object.keys(enumType).filter(key => {
    return !isNaN(Number(enumType[key])) && key !== 'once'
  })
}

export const getScheduledAt = (schedule: ISchedule) => {
  if(!schedule || !schedule.scheduled_at) return ''
  return ` - next email scheduled at: ${toDate(schedule.scheduled_at)}`
}

