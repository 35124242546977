import { computed } from 'vue'

export enum PROCESS_STATES {
  DONE = 'done',
  PROCESSING = 'processing'
}

export const getStateFromBoolean = (state: boolean): PROCESS_STATES => {
  return state ? PROCESS_STATES.PROCESSING : PROCESS_STATES.DONE
}

export const hasPendingRequests = (store) => {
  return computed(() => Object.keys(store.state.active_requests).length !== 0)
}

export const hasScheduledRequests = (store) => {
  return computed(
    () => Object.keys(store.state.scheduled_requests).length !== 0
  )
}

export const isSidekiqDone = (store) => {
  return computed(() => store.getters.sidekiq_done)
}
